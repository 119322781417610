import request from '@/utils/request'
const baseUrl = 'https://salary.renruikeji.cn'
// const testUrl = 'http://gongzi.renkangshangcheng.cn'
// 获取考勤详情
export function getAttInfo(data) {
  return request({
    url: '/work/getMobileDetails',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 确认考勤条
export function toAttOk(data) {
  return request({
    url: '/work/handAffirm',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 获取反馈记录
export function getAttFeedBack(data) {
  return request({
    url: '/work/getFeedBack',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}

// 获取头信息
export function getHeader(data) {
  return request({
    url: '/salary/getHeaders/' + data.excelId,
    method: 'get',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取用户详情
export function getUserInfo(data) {
  return request({
    url: '/salary/getSalaryDetail',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 根据code获取用户id
export function getUserId(data) {
  return request({
    url: '/login/getSalaryUseridByCode',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}
// 获取反馈记录
export function getFeedBack(data) {
  return request({
    url: '/salary/getFeedBack',
    method: 'post',
    data,
    baseURL: baseUrl
  })
}
// 确认工资条
export function toOk(data) {
  return request({
    url: '/salary/handAffirm/' + data.id,
    method: 'get',
    data,
    baseURL: baseUrl
  })
}
// 查看工资条
export function lookSalary(data) {
  return request({
    url: '/salary/updateSeeNumber/' + data.id,
    method: 'get',
    data,
    baseURL: baseUrl
  })
}


