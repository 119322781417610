<template>
  <div class="salary">
    <div class="top-box">
      <div class="top-box-button">
        <span>{{nowUserInfo.years}}考勤条</span>
        <span @click="exitUser" class="top-box-button-icon"><van-icon name="arrow-down" /></span>
      </div>
    </div>
    <div class="info-main-box">
      <div class="notice-box" v-if="nowUserInfo.notice">
        <div class="notice-box-title">
          <span style="margin-right: 10px"><van-icon name="volume-o" /></span>
          <span>公告</span>
        </div>
        <div class="notice-box-info">{{nowUserInfo.notice}}</div>
      </div>
      <div class="info-box-top">
        <div class="info-box-title">
          <div class="info-box-title-icon"></div>
          <div>基本项目</div>
        </div>
        <div class="info-box-item">
          <div>姓名</div>
          <div>{{nowUserInfo.user_name}}</div>
        </div>
        <div class="info-box-item">
          <div>员工ID</div>
          <div>{{nowUserInfo.user_id}}</div>
        </div>
      </div>
      <div class="info-box-center">
        <div class="info-box-title">
          <div class="info-box-title-icon"></div>
          <div>基本项目</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">应出勤</div>
          <div class="info-box-item-right">{{ nowUserInfo['due_attendance'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">当月实际应出勤</div>
          <div class="info-box-item-right">{{ nowUserInfo['actual_attendance'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">补上月出勤</div>
          <div class="info-box-item-right">{{ nowUserInfo['last_months_attendance'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">补上月加班费用</div>
          <div class="info-box-item-right">{{ nowUserInfo['last_months_expenses'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">转正天数</div>
          <div class="info-box-item-right">{{ nowUserInfo['regular_worker'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">当月加班天数</div>
          <div class="info-box-item-right">{{ nowUserInfo['overtime_days'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">剩余调休天数</div>
          <div class="info-box-item-right">{{ nowUserInfo['remaining_leave'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">调休换加班工资</div>
          <div class="info-box-item-right">{{ nowUserInfo['compensatory_leave'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">加班费</div>
          <div class="info-box-item-right">{{ nowUserInfo['overtime_pay'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">差旅补助</div>
          <div class="info-box-item-right">{{ nowUserInfo['travel_allowance'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">招聘奖金</div>
          <div class="info-box-item-right">{{ nowUserInfo['recruitment_bonus'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">话补</div>
          <div class="info-box-item-right">{{ nowUserInfo['telephone_subsidy'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">迟到扣款</div>
          <div class="info-box-item-right">{{ nowUserInfo['late_deduction'] || '--'}}</div>
        </div>
        <div class="info-box-item">
          <div class="info-box-item-left">其他扣款</div>
          <div class="info-box-item-right">{{ nowUserInfo['other_deduction'] || '--'}}</div>
        </div>
        <div class="info-box-item2">
          <div class="info-box-item-left">备注<span style="font-size: 12px">（加班费、差旅补助、招聘奖金、扣款等）</span></div>
          <div class="info-box-item-right" style="margin-top: 10px">{{ nowUserInfo['remark'] || '--'}}</div>
        </div>
      </div>
    </div>
    <div v-if="nowUserInfo.affirm_state !== 1" class="info-box-bottom-box">
      <span v-if="nowUserInfo.affirm_state === 0">考勤条将在 </span>
      <span v-if="nowUserInfo.affirm_state === 0" class="info-box-bottom-blue">{{nowTime}}</span>
      <span v-if="nowUserInfo.affirm_state === 0">后自动确认！</span>
      <span>如果对本考勤条有异议请及时进行反馈</span>
      <span class="info-box-bottom-blue" @click="showSendBox">马上反馈 <van-icon name="edit" /></span>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <van-popup v-model="showSend"  round position="bottom" :style="{ height: '80%' }" >
      <div class="send-box">
        <div class="send-box-title">
          <div @click="showSend = false">取消</div>
          <div>问题反馈</div>
          <div @click="sendInfo">发送</div>
        </div>
        <div>
          <van-field
                  v-model="sendMessage"
                  rows="5"
                  type="textarea"
                  placeholder="请输入反馈"
          />
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showHistory"  round position="bottom" :style="{ height: '80%' }" >
      <div class="send-box">
        <div class="send-box-title">
          <div @click="showSend = false"></div>
          <div>问题反馈</div>
          <div @click="toCloseBox">取消</div>
        </div>
        <div id="scroll_text" class="chat-info-box">
          <div v-for="item2 in feedList" :key="item2.id">
            <div class="dialog-chat-center-item" v-if="item2.spokeUserid !== userid">
              <div class="dialog-chat-center-item-title">{{item2.spokeTime}}</div>
              <div class="dialog-chat-center-info">
                <div class="dialog-chat-center-info-header">
                  <img class="chat-header-img" :src="item2.avatar || '../../assets/image/header1.jpeg'">
                </div>
                <div class="dialog-chat-center-info-text">
                  <div>{{item2.spokeUsername}}丨{{item2.deptName || '暂无'}}</div>
                  <div class="dialog-chat-center-info-text-lit">
                    {{item2.content}}
                  </div>
                </div>
              </div>
            </div>
            <div class="dialog-chat-center-item-right" v-if="item2.spokeUserid === userid">
              <div class="dialog-chat-center-item-title">{{item2.spokeTime}}</div>
              <div class="dialog-chat-center-info-right">
                <div class="dialog-chat-center-info-text">
                  <div class="name-box">{{item2.spokeUsername}}丨{{item2.deptName || '暂无'}}</div>
                  <div class="dialog-chat-center-info-text-lit-right">
                    {{item2.content}}
                  </div>
                </div>
                <div class="dialog-chat-center-info-header">
                  <img class="chat-header-img" :src="item2.avatar || '../../assets/image/header1.jpeg'">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-box-bottom">
          <div class="notice-box-tip">问题已反馈给管理员，是否已解决？</div>
          <div class="chat-box-bottom-button-box">
            <div class="chat-box-bottom-button-left" @click="toConInfo">还没，继续反馈</div>
            <div class="chat-box-bottom-button-right" @click="handelOk">已解决确认考勤条</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getAttInfo, getAttFeedBack, toAttOk, lookSalary } from '@/api/att'
import { getUserId } from '@/api/service'
import Cookies from 'js-cookie'
import { Toast } from 'vant';
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      ws: {},
      num:0,
      userid: '', // 用户id
      workId: '', // 表格id
      excelId: '',
      headers: {}, // 表头
      nowUserInfo: {}, // 当前用户信息
      show: false,
      showSend: false, // 展示反馈弹窗
      actions: [{ name: '选项一' }, { name: '选项二' }, { name: '选项三' }],
      overTime: 6000,
      nowTime: '',
      timer: {},
      sendMessage: '',
      showHistory: false,
      feedList: [],
      fromUserId: '',
      user: {}
    }},
  created() {
    // Cookies.set('userId', 'Wangleiqiang')
    let userId=Cookies.get('userId')
    const query = this.$route.query
    this.fromUserId = query.toUserId
    this.workId = query.workId
    if(!userId){
      this.login()
    }else{
      this.userid=userId
      this.getUser()
      this.connectWebSocket()
    }
  },
  mounted:function(){
  },
  methods: {
    // 退出登录
    exitUser() {
      // Cookies.remove('userId')
    },
    // 用户网页授权登录
    login(){
      let query=this.$route.query;
      if(query.code){
        let postData={}
        postData.code=query.code
        getUserId(postData).then(response => {
          if(response.code===200 || response.code===0){
            Cookies.set('userId', response.data)
            this.userid=response.data
            this.getUser()
            this.connectWebSocket()
          }else{
            Toast.fail('获取userId失败');
            return false
          }
        })
      }else{
        let nowUrl = window.location.href;
        let backurl = encodeURIComponent(nowUrl)
        let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
        window.location.href=url
      }
    },
    // 倒计时
    countDown() {
      if (this.overTime > 0) {
        let hour = Math.floor(this.overTime / (60 * 60) % 24);
        let minutes = Math.floor(this.overTime / 60 % 60);
        let seconds = Math.floor(this.overTime % 60);
        let msg = hour + "时" + minutes + "分" + seconds + "秒";
        this.nowTime = msg;
        this.overTime--
      }else{
        clearInterval(this.timer);
      }
    },
    // 格式化时间
    initTime() {
      let timestamp = Date.parse(new Date());
      let sendTimes = Date.parse(this.nowUserInfo.send_time);
      this.overTime = 60*60*1.5 - (timestamp/1000 - sendTimes/1000);
      this.timer = setInterval(()=>{
        this.countDown()
      },1000);
    },
    // 查询用户详情
    getUser() {
      const postData = {}
      postData.workId = this.workId
      console.log(postData)
      getAttInfo(postData).then(response => {
        this.nowUserInfo = response.data
        this.getFeedBack()
        this.lookSalary()
        this.initTime()
      })
    },
    // 展示反馈页面
    showSendBox() {
      this.getFeedBack()
      if (this.feedList.length > 0 ) {
        this.showHistory = true
      }else{
        this.showSend = true
      }
    },
    // 点击继续反馈
    toConInfo() {
      this.showSend = true
      this.showHistory = false
    },
    // 确认反馈
    handelOk(){
      let postData = {}
      postData.id = this.nowUserInfo.id
      toAttOk(postData).then(response => {
        console.log(response)
        this.showSend = false
        this.showHistory = false
        this.nowUserInfo.affirmState = 1
      })
    },
    // 查询用户反馈信息
    getFeedBack() {
      const postData = {}
      postData.excelId = this.nowUserInfo.excel_id
      postData.workId = this.nowUserInfo.id
      getAttFeedBack(postData).then(response => {
        console.log(response.data)
        if(response.data[0]){
          this.feedList = response.data[0].feedBacks
          this.user = response.data[0].user
          this.$nextTick(() => {
            const textarea = document.getElementById('scroll_text')
            textarea.scrollTop = textarea.scrollHeight
          })
        }
      })
    },
    // 请求查看
    lookSalary() {
      const postData = {}
      postData.id = this.nowUserInfo.id
      lookSalary(postData).then(response => {
        console.log(response)
      })
    },
    // 监听选择
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      Toast(item.name);
    },
    // 连接websocket
    connectWebSocket() {
      const that =this
      try {
        // const ws = new WebSocket('ws://192.168.1.71:8097/websocket/' + this.userid)
        // const ws = new WebSocket('ws://39.107.120.71:8097/websocket/' + this.userid)
        const ws = new WebSocket('wss://salary.renruikeji.cn/websocket/' + this.userid)
        this.ws = ws
      } catch (err) {
        console.log(err)
      }
      this.ws.onopen = function() { // 绑定连接事件
        console.log('Connection open ...')
      }
      this.ws.onmessage = function(evt) { // 绑定收到消息事件
        console.log('Received Message: ' + evt.data)
        // this.getFeedBack()
        let newData2 = evt.data
        newData2 = JSON.parse(newData2)
        const newData = {}
        newData.avatar = newData2.avatar
        newData.content = newData2.content
        newData.spokeTime = that.GetNowTime()
        newData.deptName = newData2.deptName
        newData.spokeUsername = newData2.spokeUsername
        newData.spokeUserid = newData2.spokeUserid
        that.feedList.push(newData)
        that.$nextTick(() => {
          const textarea = document.getElementById('scroll_text')
          textarea.scrollTop = textarea.scrollHeight
          console.log(textarea.scrollTop)
        })
      }
      this.ws.onclose = function() { // 绑定关闭或断开连接事件
        console.log('Connection closed.')
      }
    },
    // 取消定时
    toCloseBox() {
      this.showHistory = false
      this.getUser()
    },
    // 发送反馈
    sendInfo() {
      let sendUserId = this.fromUserId
      if(sendUserId === '') {
        Toast.fail('获取接收人信息失败');
        return
      }
      let sendData = {}
      sendData.toUserId = sendUserId
      sendData.fromUserId = this.userid
      sendData.toUserName = ''
      sendData.excelId = this.nowUserInfo.excel_id
      sendData.workId = this.nowUserInfo.id
      sendData.msg = this.sendMessage
      console.log(sendData)
      let postJson = JSON.stringify(sendData)
      this.ws.send(postJson)
      this.showSend = false
      this.showHistory = true
      this.sendMessage = ''
      this.$nextTick(() => {
        const textarea = document.getElementById('scroll_text')
        textarea.scrollTop = textarea.scrollHeight
      })
      /* const newData = {}
      newData.avatar = this.user.avatar
      newData.content = sendData.msg
      newData.spokeTime = this.GetNowTime()
      newData.spokeUsername = this.user.name
      newData.spokeUserid = this.user.userid
      newData.deptName = this.user.department
      this.feedList.push(newData)*/
      // this.getFeedBack()
    },
    GetNowTime() {
      var myDate = new Date()
      // 获取当前年
      var year = myDate.getFullYear()
      // 获取当前月
      var month = myDate.getMonth() + 1
      // 获取当前日
      var date = myDate.getDate()
      var h = myDate.getHours() // 获取当前小时数(0-23)
      var m = myDate.getMinutes() // 获取当前分钟数(0-59)
      var s = myDate.getSeconds()
      return year + '-' + this.p(month) + '-' + this.p(date) + ' ' + this.p(h) + ':' + this.p(m) + ':' + this.p(s)
    },
    p(s) {
      return s < 10 ? '0' + s : s
    }
  }
}
</script>
<style scoped>
  .salary{
    background-color: #F8F8F8;
  }
  .top-box{
    width: 100vw;
    height: 100px;
    background: #ffffff url('../../assets/image/top.jpg') no-repeat right top;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .top-box-button{
    display: flex;
    justify-content: center;
    font-size: 16px;
    border-radius: 60px;
    background-color: #D8E6E8;
    opacity: 0.9;
    height: 38px;
    line-height: 38px;
    padding: 0 15px 0 15px
  }
  .info-main-box{
    margin: -20px 5px 10px 5px;
  }
  .info-box-top{
    border-radius: 5px;
    background-color: #ffffff;
    padding: 15px 15px 0 15px;
  }
  .info-box-title{
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
  }
  .info-box-title-icon{
    width: 5px;
    height: 20px;
    background: #2586FF;
    color: #2586FF;
    margin-right: 10px;
  }
  .info-box-item{
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px dashed #E5E5E5 ;
  }
  .info-box-item2{
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    border-bottom: 1px dashed #E5E5E5 ;
  }
  .info-box-item-left{
    color: #000000;
  }
  .info-box-item-right{
    color: #666666;
  }
  .info-box-center{
    margin: 15px 3px 0 3px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 15px;
  }
  .top-box-button-icon{
    margin-left: 10px;
  }
  .info-box-bottom-box{
    color: #999999;
    margin: 8px;
    padding: 15px;
  }
  .info-box-bottom-blue{
    color: #2586FF;
  }
  .send-box{
    height: 68%;
    padding: 15px;
  }
  .send-box-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 15px;
  }
  .notice-box{
    position: relative;
    background-color: #ffffff;
    border: 1px solid #F7B500;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 3px;
  }
  .notice-box-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #F7B500;
  }
  .notice-box-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #999999;
    margin-top: 15px;
  }
  .dialog-chat-center-item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-size: 12px;
  }
  .dialog-chat-center-item-title{
    width: 100%;
    text-align: center;
    color: #666666;
  }
  .dialog-chat-center-info{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0 0 0;
  }
  .dialog-chat-center-info-header{
    margin-right: 15px;
  }
  .dialog-chat-center-info-text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #666666;
  }
  .dialog-chat-center-info-text-lit{
    padding: 10px;
    width: 200px;
    background: #F3F3F3;
    border-radius: 4px;
    margin-top: 10px;
    color: #000000;
  }
  .chat-header-img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  .chat-info-box{
    height: 100%;
    overflow-y: scroll;
    padding: 15px 0 0 0;
  }
  .dialog-chat-center-item-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-size: 12px;
  }
  .dialog-chat-center-info-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0 0 0;
  }
  .dialog-chat-center-info-text-lit-right{
    padding: 10px;
    width: 200px;
    background: #d6f1f5;
    border-radius: 4px;
    margin-top: 10px;
    color: #000000;
  }
  .name-box{
    text-align: right;
  }
  .chat-box-bottom{
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    padding: 15px 0 15px 0;
  }
  .chat-box-bottom-button-box{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    background-color: #ffffff;
  }
  .chat-box-bottom-button-left{
    width: 40vw;
    height: 50px;
    line-height: 50px;
    background-color: #eae7e7;
    color: #666666;
    border-radius: 3px;
  }
  .chat-box-bottom-button-right{
    width: 40vw;
    height: 50px;
    line-height: 50px;
    background-color: #2586FF;
    color: #ffffff;
    border-radius: 3px;
  }
  .notice-box-tip{
    margin: 15px 0 15px 0;
    color: #c9d5d2;
  }
</style>
